import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import './BookingStep.scss';
import {DayWithSlots, WeekCalendar} from "../../components/WeekCalendar/WeekCalendar";
import moment, {Moment} from "moment";
import {CONSTANTS} from "../../common/constants";
import {getDaySlotsInformation} from "../../api/day-slot.api";
import {CompanyContext} from "../../contexts/CompanyContext";
import {DaySlot} from "../../models/day-slot";
import {Button} from "../../components/ui-components/Button/Button";
import {ToastService} from "../../service/ToastService";


interface BookingStepProps {
    onSubmit: (activeSlot: DaySlot) => void;
    onSetContactMode: () => void;
}

export const BookingStep: FunctionComponent<BookingStepProps> = (props) => {
    const [dayWithSlots, setDayWithSlots] = useState<DayWithSlots[]>([]);
    const [activeDay, setActiveDay] = useState<DayWithSlots>({} as DayWithSlots);
    const [activeSlot, setActiveSlot] = useState<DaySlot | null>(null);
    const company = useContext(CompanyContext).company;

    const generateDays = (startDate: Moment) => {
        const dates: Moment[] = [];
        for (let i = 0; i < CONSTANTS.DAY_IN_WEEK; i++) {
            dates.push(startDate.clone().add(i, 'day'));
        }
        return dates;
    }

    const loadDaysWithSlots = async (days: Moment[]) => {
        const dayWithSlots = await getDaySlotsInformation(company?.id as number, days)
            .catch((error: Error) => {
                if (error && error.message) {
                    ToastService.middleScreenErrorToast(error.message);
                } else {
                    ToastService.middleScreenErrorToast(CONSTANTS.UNKNOWN_ERROR);
                }
            });
        if (dayWithSlots) {
            setDayWithSlots(dayWithSlots);
            setFirstActiveDay(dayWithSlots);
        }
    }

    const setFirstActiveDay = (dayWithSlots: DayWithSlots[]) => {
        for (let day of dayWithSlots) {
            if (day.slots.length) {
                updateActiveDay(day);
                return;
            }
        }
    }

    useEffect(() => {
        if (company?.id) {
            const startOfWeek = moment().startOf('isoWeek');
            const days = generateDays(startOfWeek);
            loadDaysWithSlots(days);
        }
    }, [company?.id]);

    const updateActiveDay = (activeDay: DayWithSlots) => {
        setActiveDay(activeDay);
        updateActiveSlot(null);
    }

    const setContactMode = () => {
        props.onSetContactMode();
    }

    const updateActiveSlot = (slot: DaySlot | null) => {
        setActiveSlot(slot);
    }

    const goNextStep = () => {
        props.onSubmit(activeSlot as DaySlot);
    }

    const goToNewDay = (newDay: Moment) => {
        updateActiveSlot(null);
        const days = generateDays(newDay);
        loadDaysWithSlots(days);
    }

    return (
        <section className='step-container'>
            <h1>Select a booking date</h1>
            <section className="booking-form-container">
                <div className="week-calendar-container">
                    <WeekCalendar activeDay={activeDay} onSetActiveDay={updateActiveDay} onSetContactMode={setContactMode}
                                  dayWithSlots={dayWithSlots} activeWeekDaySlot={activeSlot}
                                  onSetActiveSlot={updateActiveSlot} onGoToNewDay={goToNewDay}/>
                </div>

                <div className="buttons">
                    <Button disabled={!activeSlot} onClick={goNextStep}>Next</Button>
                </div>
            </section>
        </section>
    );
};
