import {SETTINGS} from "../common/settings";
import moment, {Moment} from "moment";
import {DaySlot} from "../models/day-slot";
import {DayWithSlots} from "../components/WeekCalendar/WeekCalendar";

export const getDaySlotsInformation = async (companyID: number, days: Moment[]) => {
    const startTime = days[0].clone().startOf('day');
    const endTime = days[days.length - 1].clone().endOf('day');
    return fetch(`${SETTINGS.getApiUrl()}/portal/company/slots/${companyID}?limit=100000&start=${startTime.unix()}&end=${endTime.unix()}`, {method: 'GET'})
        .then((response) => {
            return response.json();
        })
        .then((response: { response: { slots: DaySlot[] }, error: boolean, errors?: string[] }) => {
            if (response.error) {
                throw new Error((response.errors || []).join('\n'));
            }
            let dayWithSlots: DayWithSlots[] = [];
            for (let day of days) {
                dayWithSlots.push({day, slots: []});
            }
            const today = moment();
            for (let slot of response.response.slots) {
                const slotStartTime = moment(moment.unix(slot.start_timestamp));
                const slotEndTime = moment(moment.unix(slot.start_timestamp));
                slot.endTime = slotEndTime;
                slot.startTime = slotStartTime;
                if (slotStartTime.isBefore(today)) {
                    continue;
                }
                for (let dayWithSlot of dayWithSlots) {
                    if (dayWithSlot.day.isSame(slotStartTime, 'date')) {
                        dayWithSlot.slots.push(slot);
                    }
                }
            }
            return dayWithSlots;
        })
}
