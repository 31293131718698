import React, {FunctionComponent, useCallback, useContext} from 'react';
import {CONSTANTS} from "../../common/constants";
import './NotSelectedStep.scss';
import {CompanyContext} from "../../contexts/CompanyContext";


interface NotSelectedStepProps {
    onSelectStep: (step: number) => void;
}

export const NotSelectedStep: FunctionComponent<NotSelectedStepProps> = ((props) => {
    const company = useContext(CompanyContext).company;

    const isScheduleAvailable = useCallback(() => {
        return company?.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.SCHEDULE
            || company?.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.SCHEDULE_ONLY;
    }, [company]);

    const isContactAvailable = useCallback(() => {
        return company?.customer_portal_mode !== CONSTANTS.BOOKING_PORTAL_MODES.SCHEDULE_ONLY;
    }, [company]);

    const isServiceAvailable = useCallback(() => {
        return company?.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.SERVICE;
    }, [company]);

    const isServiceAndScheduleAvailable = useCallback(() => {
        return company?.customer_portal_mode === CONSTANTS.BOOKING_PORTAL_MODES.SCHEDULE_AND_SERVICE;
    }, [company]);

    return (
        <section className='step-container not-selected-step-container'>
            {
                company?.logo?.url && <section className="logo-section">
                    <img className='main-aside-logo' alt='logo' src={company?.logo?.url}/>
                </section>
            }

            <p className='welcome-text'>{company?.customer_portal_welcome_text}</p>

            <section className="booking-buttons-container">
                {isContactAvailable() && <article className='booking-option' onClick={() => {
                    props.onSelectStep(CONSTANTS.STEPS.CONTACT);
                }}>
                    <img src="/assets/images/contact-option.svg" alt="contact"/>

                    <p className="booking-option-link">Contact Us</p>
                </article>}

                {
                    isScheduleAvailable()
                    && <article className='booking-option' onClick={() => {
                        props.onSelectStep(CONSTANTS.STEPS.BOOKING);
                    }}>
                        <img src="/assets/images/schedule-option.svg" alt="contact"/>

                        <p className="booking-option-link">Schedule An Appointment</p>
                    </article>
                }

                {
                    isServiceAvailable()
                    && <article className='booking-option' onClick={() => {
                        props.onSelectStep(CONSTANTS.STEPS.SERVICE);
                    }}>
                        <img src="/assets/images/service-option.svg" alt="contact"/>

                        <p className="booking-option-link">Book a Service</p>
                    </article>
                }

                {
                    isServiceAndScheduleAvailable()
                    && <article className='booking-option' onClick={() => {
                        props.onSelectStep(CONSTANTS.STEPS.BOOKING_AND_SERVICE);
                    }}>
                        <img src="/assets/images/service-and-schedule-option.svg" alt="contact"/>

                        <p className="booking-option-link">Schedule An Appointment and Book a Service</p>
                    </article>
                }
            </section>
        </section>
    )
});
