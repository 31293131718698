import React, {FunctionComponent, useCallback, useContext, useEffect, useState} from 'react';
import {DayWithSlots} from "../WeekCalendar/WeekCalendar";
import moment, {Moment} from "moment";
import {getDaySlotsInformation} from "../../api/day-slot.api";
import {CompanyContext} from "../../contexts/CompanyContext";
import {MonthDay} from "./MonthDay/MonthDay";
import {ToastService} from "../../service/ToastService";
import {CONSTANTS} from "../../common/constants";
import "./MonthCalendar.scss";
import {Preloader} from "../Preloader/Preloader";

interface MonthCalendarProps {
    activeDay: DayWithSlots;
    onSetActiveDay: (dayWithSlot: DayWithSlots) => void;
    onClose: () => void;
}

export const MonthCalendar: FunctionComponent<MonthCalendarProps> = (props) => {
    const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const [daysWithSlots, setDaysWithSlots] = useState<DayWithSlots[]>([]);
    const [activeDay, setActiveDay] = useState<DayWithSlots | null>(null);
    const company = useContext(CompanyContext).company;

    const getLeftLeverClasses = useCallback(() => {
        const classes = ['month-lever'];
        if (daysWithSlots.length && daysWithSlots[0].day.isSameOrBefore(moment())) {
            classes.push('inactive');
        }
        return classes.join(' ');
    }, [daysWithSlots]);

    const goToPreviousMonth = () => {
        const days = generateDays((activeDay?.day as Moment).clone().subtract(1, 'month'));
        loadDaysWithSlots(days);
    }

    const goToNextMonth = () => {
        const days = generateDays((activeDay?.day as Moment).clone().add(1, 'month'));
        loadDaysWithSlots(days);
    }

    const closeCalendar = () => {
        props.onClose();
    }

    const setToday = () => {
        const days = generateDays(moment());
        loadDaysWithSlots(days);
    }

    const goToCurrentActiveDay = () => {
        props.onSetActiveDay(props.activeDay);
        closeCalendar();
    }

    const generateDays = (activeDay: Moment) => {
        let firstDayOfMonth = activeDay.clone().date(1);
        const monday = moment().isoWeekday('Monday').isoWeekday();
        firstDayOfMonth = firstDayOfMonth.clone().subtract(firstDayOfMonth.isoWeekday() - monday, 'day');
        let lastDayOfMonth = activeDay.clone().date(1).clone().add(1, 'month').subtract(1, 'day');
        const sunday = moment().isoWeekday('Sunday').isoWeekday();
        lastDayOfMonth = lastDayOfMonth.clone().add(sunday - lastDayOfMonth.isoWeekday(), 'day');
        const days: Moment[] = [];
        while (firstDayOfMonth.isSameOrBefore(lastDayOfMonth, 'date')) {
            days.push(firstDayOfMonth.clone());
            firstDayOfMonth = firstDayOfMonth.add(1, 'day');
        }
        return days;
    }

    const loadDaysWithSlots = async (days: Moment[]) => {
        setDaysWithSlots([]);
        const dayWithSlots = await getDaySlotsInformation(company?.id as number, days)
            .catch((error: Error) => {
                if (error && error.message) {
                    ToastService.middleScreenErrorToast(error.message);
                } else {
                    ToastService.middleScreenErrorToast(CONSTANTS.UNKNOWN_ERROR);
                }
            });
        if (dayWithSlots) {
            setDaysWithSlots(dayWithSlots);
            setFirstActiveDay(dayWithSlots)
        }
    }

    const updateActiveDay = (activeDay: DayWithSlots) => {
        props.onSetActiveDay(activeDay);
        props.onClose();
    }

    const setFirstActiveDay = (dayWithSlots: DayWithSlots[]) => {
        for (let day of dayWithSlots) {
            if (day.day.isSame(props.activeDay.day, 'day')) {
                if (!activeDay?.day.isSame(day.day, 'date')) {
                    setActiveDay(day);
                }
                return;
            }
        }
        for (let day of dayWithSlots) {
            if (day.slots.length) {
                if (!activeDay?.day.isSame(day.day, 'date')) {
                    setActiveDay(day);
                }
                return;
            }
        }
    }

    useEffect(() => {
        setActiveDay(props.activeDay);
    }, [props.activeDay]);

    useEffect(() => {
        if (activeDay) {
            if(!activeDay.day) {
                setActiveDay({day: moment(), slots: []});
                return;
            }
            const days = generateDays((activeDay?.day as Moment) || moment());
            loadDaysWithSlots(days);
        }
    }, [activeDay]);

    const isActiveDay = (dayWithSlots: DayWithSlots) => {
        return dayWithSlots.day.isSame(activeDay?.day);
    };

    return (
        <div className='month-calendar'>
            {daysWithSlots?.length ? <>
                <header>
                    <i onClick={goToPreviousMonth} className={`fad fa-2x fa-angle-left ${getLeftLeverClasses()}`}/>

                    <div className='month-year-title'>
                        {activeDay?.day.format('MMMM')} {activeDay?.day.format('YYYY')}
                    </div>

                    <i className='fad fa-2x fa-angle-right' onClick={goToNextMonth}/>
                </header>

                <section className='month-calendar-week-days-container'>
                    {dayNames.map((dayName) => {
                        return <div className='day-name' key={dayName}>{dayName}</div>
                    })}
                </section>

                <section className='month-calendar-month-days-container'>
                    {daysWithSlots.map((dayWithSlots, index: number) => {
                        return <MonthDay key={index} dayWithSlot={dayWithSlots} isActive={isActiveDay(dayWithSlots)}
                                         onSetActiveDay={updateActiveDay}/>
                    })}
                </section>

                <footer>
                    <p className='today-link' onClick={setToday}>
                        <i className="fad fa-calendar-day"/>
                        Today
                    </p>
                    <p className='close-link' onClick={closeCalendar}>
                        <i className="fad fa-times"/>
                        Close
                    </p>
                </footer>
            </> : <div className='preloader-container'><Preloader/></div>}
        </div>
    );
}
;
